import {fetchJson} from './UtilAjax';

const baseUrl = document.location.origin+'/';
const countSocietiesUrl =  baseUrl + "get-society-count";
const mapUrl = baseUrl+"get-maps";
const mapUrlNbPages = baseUrl+"get-maps-nbpages";
const originUrl = baseUrl+"get-origins";
const sectorUrl = baseUrl+"get-sectors";
const judicialUrl = baseUrl+"get-judicials";
const capitalUrl =  baseUrl+"get-capital";
const capitalAveragelUrl = baseUrl+"get-capital-average";
const dirigeantAgeMoyen = baseUrl+"get-dirigeant-ageMoyen";
const dirigeantNationaliteUrl = baseUrl + "get-dirigeants-nationalite";
const countDirigeantsUrl = baseUrl + "count-dirigeants";
const dirigeantCiviliteUrl = baseUrl + "get-dirigeants-civilite";
const dirigeantTranchesAgeUrl = baseUrl + "get-dirigeants-ages";
const getJudicialDataUrl = baseUrl + "get-judicial-datas";
const getJudicialEffectifUrl = baseUrl + "get-judicial-effectifs";
//Maps
export  function getMaps(data){
    return fetchJson(mapUrl,{
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data)=> data)
        ;
}
export  function getMapsNbpages(data){
    return fetchJson(mapUrlNbPages,{
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data)=> data)
        ;
}
//forme juridique
export  function getJudicial(data){
    return fetchJson(judicialUrl,{
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data)=> data)
        ;
}

//origine du  fond
export  function getOrigin(data){
    return fetchJson(originUrl,{
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data)=> data)
        ;
}


// secteur d'activité
export  function getActivity(data){
    return fetchJson(sectorUrl,{
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data)=> data)
        ;
}

// capital
export function getAgeMoyenDirigeant(data){
    return fetchJson(dirigeantAgeMoyen, {
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data) => data)
        ;
}
export  function getCapital(data){
    return fetchJson(capitalUrl,{
            method:'POST',
            body: data
        }
    ).then((data)=> data)
        ;
}
export  function getCapitalAverage(data){
    return fetchJson(capitalAveragelUrl,{
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data)=> data)
        ;
}
export function getDirigeantNationalite(data){
    return fetchJson(dirigeantNationaliteUrl, {
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data) => data)
        ;
}
export function getDirigeantCivilite(data){
    return fetchJson(dirigeantCiviliteUrl, {
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data) => data)
        ;
}
export function getDirigeantTranchesAge(data){
    return fetchJson(dirigeantTranchesAgeUrl, {
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data) => data)
        ;
}
export function countSocieties(data){
    return fetchJson(countSocietiesUrl, {
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data) => data)
        ;
}
export function countDirigeants(data){
    return fetchJson(countDirigeantsUrl, {
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data) => data)
        ;
}

// entreprise en difficultés

export function JudicialData(data){
    return fetchJson(getJudicialDataUrl, {
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data) => data)
        ;
}

export function JudicialEffectif(data){
    return fetchJson(getJudicialEffectifUrl, {
            method:'POST',
            body: JSON.stringify(data)
        }
    ).then((data) => data)
        ;
}
