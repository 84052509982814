export   function  checkStatus(response) {
        //console.log(response);
        if (response.status >= 200 && response.status < 400) {
            return response;
        }
        if(response.statusCode == 0)
        {
            return response;
        }

        const error = new Error(response.status);
        error.response = response;
        throw error;

    }

/**
 *
 * @param url
 * @param options
 * @returns {Promise<any[] | never>}
 */
 export   function  fetchJson(url, options) {
        let headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',

        };

        if (options && options.headers) {
            //we are mergins current header with initial request headers
            headers = {...options.headers, headers};
           // delete options.headers;
        }
        //object assign is the same as array_merge for object
        return fetch(url, Object.assign({
            mode: 'no-cors',
            headers: headers
        }, options))
            .then(checkStatus)
            .then(

                //prevent error for no json response
                response => {
                    return response.text()
                        .then(text => text ? JSON.parse(text) : '')
                }
            )
    }
